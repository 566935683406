import { TTokenData } from 'react-oauth2-code-pkce/dist/Types';
import { useFragment } from '../__generated__';
import {
  FragmentErrorFragment,
  FragmentErrorFragmentDoc,
  FragmentStyleFragment,
  FragmentStylePublicFragment,
} from '../__generated__/graphql';
import { ApolloError } from '@apollo/client';

export const isBrowser = () => typeof window !== 'undefined';

/**
 * @returns The Google Tag Manager data layer on which to push events and data variables. Initialization is handled by
 *          the gatsby-plugin-google-tagmanager.
 */
export const getGTMDataLayer = () => (window as any).dataLayer as any[];

/**
 * Push an event to Google Tag Manager.
 * @param eventName The name of the event pushed to GTM.
 * @param mergeEvent The object containing the event data. The "event" field will be overwritten with value eventName.
 */
export const pushEventToGTMDataLayer = (
  eventName: (typeof GTM_EVENT_NAMES)[keyof typeof GTM_EVENT_NAMES],
  mergeEvent: object = {},
) => {
  getGTMDataLayer().push({ ...mergeEvent, event: eventName });
};

/**
 * Push an event to Google Tag Manager, and call a callback once by the specified timeout.
 * @param eventName The name of the event pushed to GTM.
 * @param callback The callback.
 * @param callbackTimeoutMillis The timeout to wait for the GTM event to be processed before calling the callback.
 * @param mergeEvent The object containing the event data. The "event" field will be overwritten with value eventName,
 *                   and the "eventCallback" and "eventTimeout" fields will be overwritten.
 */
export const pushEventToGTMDataLayerWithCallback = (
  eventName: (typeof GTM_EVENT_NAMES)[keyof typeof GTM_EVENT_NAMES],
  callback: () => void,
  callbackTimeoutMillis: number,
  mergeEvent: object = {},
) => {
  let abandon = false;
  const atMostOnceCallback = () => {
    if (!abandon) {
      callback();
      abandon = true;
    }
  };

  pushEventToGTMDataLayer(eventName, {
    ...mergeEvent,
    // Attempt to use the GTM eventCallback and eventTimeout way of calling the callback, but we can't rely on it in
    // case e.g. GTM is blocked by the browser.
    // https://www.simoahava.com/gtm-tips/use-eventtimeout-eventcallback/
    eventCallback: atMostOnceCallback,
    eventTimeout: callbackTimeoutMillis,
  });
  // Rely on setTimeout to guarantee it runs at least once.
  setTimeout(atMostOnceCallback, callbackTimeoutMillis);
};

export const GTM_EVENT_NAMES = {
  LOGIN: 'login',
  LOGOUT: 'logout',
  STYLE_CHOICE_DISLIKED: 'style_choice_disliked',
  STYLE_CHOICE_LIKED: 'style_choice_liked',
  STYLE_CHOICE_SAVED: 'style_choice_saved',
  STYLE_CHOICE_UNDO: 'style_choice_undo',
  STYLE_CHOICE_DELETED: 'style_choice_deleted',
  STYLE_CHOICE_PREV_PICTURE: 'style_choice_prev_picture',
  STYLE_CHOICE_NEXT_PICTURE: 'style_choice_next_picture',
  STYLE_CHOICE_NAVIGATE_PROFILE: 'style_choice_navigate_profile',
  STYLE_CHOICE_NAVIGATE_STYLE: 'style_choice_navigate_style',
  PROFILE_SET_UP_SKIP: 'profile_set_up_skip',
  PROFILE_SET_UP_CONFIRM: 'profile_set_up_confirm',
  PROFILE_EDIT_CONFIRM: 'profile_edit_confirm',
  PROFILE_EDIT_USERNAME_CONFIRM: 'profile_edit_username_confirm',
  PROFILE_NAVIGATE_STYLE: 'profile_navigate_style',
  PROFILE_NAVIGATE_BOOKING_LINK: 'profile_navigate_booking_link',
  PROFILE_NAVIGATE_INSTAGRAM: 'profile_navigate_instagram',
  STYLE_CREATE_CONFIRM: 'style_create_confirm',
  STYLE_DELETE_CONFIRM: 'style_delete_confirm',
  STYLE_PREV_PICTURE: 'style_prev_picture',
  STYLE_NEXT_PICTURE: 'style_next_picture',
  COMMENT_ROOT_CREATE_CONFIRM: 'comment_root_create_confirm',
  COMMENT_ROOT_DELETE_CONFIRM: 'comment_root_delete_confirm',
  COMMENT_REPLY_CREATE_CONFIRM: 'comment_reply_create_confirm',
  COMMENT_REPLY_DELETE_CONFIRM: 'comment_reply_delete_confirm',
  COMMENT_NAVIGATE_PROFILE: 'comment_navigate_profile',
  COMMENT_LIKE_CREATE_CONFIRM: 'comment_like_create_confirm',
  COMMENT_LIKE_DELETE_CONFIRM: 'comment_like_delete_confirm',
} as const;

// Web Storage API (localStorage / sessionStorage) constants.
export const FAYD_STORAGE_KEY_PREFIX = 'FAYD_';
export const FAYD_STORAGE_KEY_SHOULD_PROCESS_LOGIN_ONCE = FAYD_STORAGE_KEY_PREFIX + 'shouldProcessLoginOnce';

/**
 * Get the JWT auth token expire epoch timestamp.
 *
 * Access the localStorage key managed by react-oauth2-pkce directly.
 * https://github.com/soofstad/react-oauth2-pkce/blob/9bfa6ab27/src/AuthContext.tsx#L42
 *
 * Check for expiry in the same way as epochTimeIsPast() in react-oauth2-pkce.
 * https://github.com/soofstad/react-oauth2-pkce/blob/9bfa6ab27/src/timeUtils.ts#L13
 *
 * TODO: Figure out a less fragile way to do this.
 *
 * @param withinSeconds
 * @returns True if token is expired/expiring within withinSeconds.
 */
export const isAuthTokenExpired = (withinSeconds = 0): boolean => {
  const tokenExpireEpochTimeSeconds = JSON.parse(localStorage.getItem('ROCP_tokenExpire') ?? '0');
  return (
    !(typeof tokenExpireEpochTimeSeconds === 'number') ||
    Math.round(Date.now()) / 1000 + withinSeconds >= tokenExpireEpochTimeSeconds
  );
};

export type Nullable<T> = {
  [K in keyof T]: T[K] | null;
};

export type PartialNullable<T> = Partial<Nullable<T>>;

/**
 * Return the string format for the S3 key with bucket.
 * @param bucket S3 bucket
 * @param key S3 key without bucket
 * @returns <bucket>#<key> if both are defined; otherwise, empty string ''.
 */
export const s3KeyWithBucket = (bucket?: string, key?: string): string | undefined => {
  if (bucket !== undefined && key !== undefined) {
    return `${bucket}#${key}`;
  } else {
    return undefined;
  }
};

export const parseS3KeyWithBucket = (s3KeyWithBucket?: string): { bucket: string; key: string } | undefined => {
  if (s3KeyWithBucket !== undefined && s3KeyWithBucket.split('#').length === 2) {
    return { bucket: s3KeyWithBucket.split('#')[0], key: s3KeyWithBucket.split('#')[1] };
  } else {
    return undefined;
  }
};

export const classesToPreventInlineBlockElementsHavingSpaceOnTop = ' border-0 align-top ';

export const propsForSafeExternalLink = { target: '_blank', rel: 'noopener noreferrer' };

export const isNotFoundError = (
  errorFragment: { __typename?: 'Error' } & { ' $fragmentRefs'?: { FragmentErrorFragment: FragmentErrorFragment } },
): boolean => {
  return useFragment(FragmentErrorFragmentDoc, errorFragment).name === 'NotFoundError';
};

/**
 * Return the human readable string for the given number.
 * @param value The number value.
 * @returns The human readable string. E.g. 0, 1, 11, 111, 1.1K, 11.1K, 111.1K, 1.1M, 11.1M, 111.1M, 1.1B, 11.1B, 111.1B.
 */
export const numberToApproxHumanReadableString = (value: number): string => {
  const suffixes = ['K', 'M', 'B'];
  const suffixThreshold = 1e3;

  let idx = -1;
  while (value >= suffixThreshold && ++idx < suffixes.length) {
    value /= suffixThreshold;
  }

  return String(idx === -1 ? value : value.toFixed(1) + suffixes[idx]);
};

const MILLIS_IN_SECOND = 1000;
const MILLIS_IN_MINUTE = 60 * MILLIS_IN_SECOND;
const MILLIS_IN_HOUR = 60 * MILLIS_IN_MINUTE;
const MILLIS_IN_DAY = 24 * MILLIS_IN_HOUR;
const MILLIS_IN_WEEK = 7 * MILLIS_IN_DAY;
const MILLIS_IN_YEAR = 365 * MILLIS_IN_DAY;
/**
 * Return the human readable string of time since the given epochMillis timestamp number.
 * @param value The epochMillis timestamp number value.
 * @returns The human readable string. E.g. 1s, 1m, 1h, 1d, 1w, 1y.
 */
export const epochMillisToApproxTimeSinceHumanReadableString = (epochMillis: number): string => {
  // Positive meaning timestamp in past (current time > timestamp) is expected. Negative means timestamp in future.
  let durationMillis = Date.now() - epochMillis;
  const isNegative = durationMillis < 0;
  if (isNegative) {
    durationMillis = durationMillis * -1;
  }

  const suffixes = ['s', 'm', 'h', 'd', 'w', 'y'];
  const suffixThresholdsMillis = [
    MILLIS_IN_SECOND,
    MILLIS_IN_MINUTE,
    MILLIS_IN_HOUR,
    MILLIS_IN_DAY,
    MILLIS_IN_WEEK,
    MILLIS_IN_YEAR,
  ];

  let idx = suffixes.length - 1;
  while (idx > 0 && idx < suffixThresholdsMillis.length && durationMillis < suffixThresholdsMillis[idx]) {
    idx--;
  }
  const durationInSuffixUnit = durationMillis / suffixThresholdsMillis[idx];

  // durationInSuffixUnit.toFixed(0) !== '0' because otherwise we could end up with e.g. '-0s'.
  return (
    (isNegative && durationInSuffixUnit.toFixed(0) !== '0' ? '-' : '') + durationInSuffixUnit.toFixed(0) + suffixes[idx]
  );
};

export function isFragmentStyleFragment(
  style: FragmentStyleFragment | FragmentStylePublicFragment,
): style is FragmentStyleFragment {
  return 'requestedStylePictures' in style;
}

export function isAdmin(token?: TTokenData): boolean {
  return (
    token !== undefined &&
    token['cognito:groups'] !== undefined &&
    Array.isArray(token['cognito:groups']) &&
    (token['cognito:groups'] as string[]).includes('admin')
  );
}

/**
 * Prepend "https://" to the provided URL string if "http://" or "https://" are not already present at the start.
 * Useful because the backend accepts URLs with or without "http://" or "https://", but external links in href must
 * start with "http://" or "https://" to lead externally or else the link will be considered relative and be appended
 * to the current URL.
 * @param url
 * @returns url trimmed and guaranteed to start with "http://" or "https://"
 */
export function prependHttpsToUrlIfNotPresent(url: string): string {
  let newUrl = url.trim();
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    newUrl = 'https://' + newUrl;
  }
  return newUrl;
}

/**
 * Simplifies the provided URL string by removing "http://" or "https://" if present and removing anything after
 * / or ? after the fully qualified domain name.
 * @param url
 * @returns simplified url
 */
export function getSimpleUrl(url: string): string {
  let newUrl = url.trim();

  if (newUrl.startsWith('http://')) {
    newUrl = newUrl.slice(7);
  } else if (newUrl.startsWith('https://')) {
    newUrl = newUrl.slice(8);
  }
  // Guaranteed to have removed the / symbols from the protocol by this point.

  const suffixChars = ['/', '?', '#'];
  for (const suffixChar of suffixChars) {
    const index = newUrl.indexOf(suffixChar);
    if (index != -1) {
      newUrl = newUrl.slice(0, index);
    }
  }

  return newUrl;
}

export type TFaydApiErrors = ({
  __typename?: 'Error' | undefined;
} & {
  ' $fragmentRefs'?:
    | {
        FragmentErrorFragment: FragmentErrorFragment;
      }
    | undefined;
})[];

export function faydApiErrorsToErrorMessages(faydApiErrors: TFaydApiErrors): string[] {
  return faydApiErrors.map((fragment) => {
    const faydApiError = useFragment(FragmentErrorFragmentDoc, fragment);
    return `${faydApiError.name}: ${faydApiError.message}`;
  });
}

export function apolloErrorToErrorMessage(apolloError: ApolloError): string {
  // The errors are also split out into graphQLErrors, protocolErrors, clientErrors, networkError, but let's just rely
  // on the overall errorMessage which is simplest to implement and generally pretty readable.
  return `Server Error: ${apolloError.message}`;
}

export function preventFormSubmitOnEnter(e: React.FormEvent<HTMLFormElement>) {
  e.preventDefault();
}
